import React from 'react';
import { Link } from 'react-router-dom';
import { social } from '../data/data';
import logo from '../assets/images/funlandia_logo_podst_siec1.png';
import { motion } from 'framer-motion';

export default function Footer() {
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } },
  };

  const hoverEffect = {
    scale: 1.1,
    transition: { type: 'spring', stiffness: 300 },
  };
  return (
    <div>
      <motion.footer
        className="footer py-4 jarallax bg-footer "
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        {/* <ParallaxBg bg={bg8} /> */}
        <div className="bg-overlay " style={{ backgroundColor: '#8CC63F' }}></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <Link to="/">
                <motion.img src={logo} height="85" alt="Funlandia Logo" whileHover={hoverEffect} />
              </Link>
              <div className="content mt-4 pt-2">
                <motion.h6 className="address text-light fw-normal" whileHover={hoverEffect}>
                  <Link
                    to="#!"
                    className="video-play-icon text-white title-dark"
                    style={{ fontSize: 18 }}
                  >
                    295 N Northwest Hwy, Unit B, Palatine, IL 60067
                  </Link>
                </motion.h6>
                <motion.p className="ph-no mb-0 text-light fw-normal" whileHover={hoverEffect}>
                  <Link
                    to="tel:+152534-468-854"
                    className="text-white title-dark"
                    style={{ fontSize: 18 }}
                  >
                    +1 773-765-8047
                  </Link>
                </motion.p>
                <motion.p className="emailid text-light fw-normal" whileHover={hoverEffect}>
                  <Link
                    to="mailto:contact@example.com"
                    className="text-white title-dark"
                    style={{ fontSize: 18 }}
                  >
                    funlandiacenter@gmail.com
                    {/* funlandiacafe@gmail.com */}
                  </Link>
                </motion.p>
                <ul className="list-instyled ps-0 mb-0 social-icon social text-center">
                  {social.map((item, index) => {
                    return (
                      <motion.li
                        className="list-inline-item text-white "
                        key={index}
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }} // Slight tap effect
                        style={{ margin: '0 10px' }}
                      >
                        <Link to="#" style={{ color: '#fff' }}>
                          <i className={item}></i>
                        </Link>
                      </motion.li>
                    );
                  })}
                </ul>

                {/* Inline Privacy Policy and Terms of Use */}
                {/* <div style={{ marginBottom: "10px" }}>
                  <Link to="/privacy-policy" className="text-white">
                    Privacy Policy
                  </Link>
                  <span style={{ margin: "0 10px" }}>|</span> 
                  <Link to="/terms-of-use" className="text-white">
                    Terms of Use
                  </Link>
                </div> */}

                <p className="mb-0 text-foot">
                  © {new Date().getFullYear()} Funlandia. Design & Develop by{' '}
                  <Link to="https://kddziekan.com/" target="_blank" className="text-reset">
                    KD Dziekan Corp
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </motion.footer>
    </div>
  );
}
