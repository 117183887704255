import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { isMobile } from '../utils/helper';
import { packagesData } from '../data/data';
import { Tabs, Tab } from 'react-bootstrap';
import '../assets/css/Reservation.css';

export default function Reservation() {
  const [isweekend, setIsweekend] = useState(false);

  const handleToggle = () => {
    setIsweekend(!isweekend);
  };

  const titleAnimation = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div
      className="bg-white"
      style={{
        paddingTop: isMobile ? '20%' : '10%',
        paddingBottom: '5%',
      }}
    >
      <section className="section bg-white" id="reservation">
        <div className="container">
          {/* Section Header */}
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title mb-4 pb-2">
                <motion.h4
                  className="title text-uppercase fw-bold mb-4"
                  initial="hidden"
                  animate="visible"
                  variants={titleAnimation}
                  transition={{ duration: 0.8, ease: 'easeInOut' }}
                >
                  Reservations
                </motion.h4>
                <motion.p
                  className="text-muted mx-auto para-desc"
                  initial="hidden"
                  animate="visible"
                  variants={titleAnimation}
                  transition={{ duration: 0.8, ease: 'easeInOut' }}
                >
                  Booking a birthday party or group playdate? Secure your reservation with us to
                  enjoy exclusive access to our play areas. Contact us for available dates and
                  special packages.
                </motion.p>
              </div>
            </div>
          </div>

          {/* Pricing Toggle */}
          <div className="row justify-content-center mb-4">
            <div className="col-auto">
              <div className="form-check form-switch custom-toggle">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="pricingToggle"
                  checked={isweekend}
                  onChange={handleToggle}
                />
                <label className="form-check-label" htmlFor="pricingToggle">
                  {isweekend
                    ? 'Friday, Saturday, Sunday Pricing'
                    : 'Standard Pricing (15% off Weekdays)'}
                </label>
              </div>
            </div>
          </div>

          {/* Packages */}
          <div className="row">
            {packagesData.map((packageItem) => (
              <div className="col-lg-6 col-md-12 col-12 mt-4 pt-2 d-flex" key={packageItem.id}>
                <div className="service-container position-relative overflow-hidden hover-bg shadow rounded text-center w-100">
                  <div className="content p-4">
                    <span className="number p-3 pt-0 border-bottom rounded-circle">
                      {packageItem.id}
                    </span>
                    <h5 className="title text-dark mt-4">{packageItem.title}</h5>
                    {/* <p className="text-muted serv-pera mb-3">{packageItem.desc}</p> */}
                    {/* <h5 className="title " style={{ color: '#41C1CC', fontSize: 20 }}>
                      <strong>{packageItem.name}</strong>
                    </h5> */}

                    {/* Tabs for Pricing Tiers */}
                    <div className="custom-tabs">
                      <Tabs
                        defaultActiveKey={packageItem.pricing[0].kids}
                        id={`pricing-tabs-${packageItem.id}`}
                        className="mb-3 mt-3"
                        fill
                      >
                        {packageItem.pricing.map((tier) => (
                          <Tab eventKey={tier.kids} title={`${tier.kids} Kids`} key={tier.kids}>
                            {/* Pricing Details */}
                            <div className="package-details text-left mt-3">
                              {/* {isweekend ? null : <h3>15% off Weekdays</h3>} */}
                              <p>
                                <strong>Guests:</strong>
                              </p>
                              <div className="pricing-tier mb-2">
                                <strong>
                                  {tier.kids} Kids:{' '}
                                  {isweekend
                                    ? `Friday, Saturday, Sunday: $${tier.weekendPrice}`
                                    : `Standard: $${tier.standardPrice}`}
                                </strong>
                              </div>

                              {/* Additional Child Pricing */}
                              <div className="additional-child mt-3">
                                <p>
                                  <strong>Additional Children:</strong>
                                </p>
                                <div className="additional-price">
                                  <p className="mb-1">
                                    <strong>
                                      {isweekend ? 'Friday, Saturday, Sunday' : 'Standard'}:
                                    </strong>
                                  </p>
                                  {/* Display only the additional child price for the current tier */}
                                  <div className="additional-price-tier mb-2">
                                    <strong>Beyond {tier.kids} Kids:</strong> $
                                    {isweekend
                                      ? packageItem.additionalChildPrice.weekend[tier.kids]
                                      : packageItem.additionalChildPrice.standard[tier.kids]}{' '}
                                    per additional child
                                  </div>
                                </div>
                              </div>

                              {/* <p className="mt-2">
                              <strong>Adults:</strong> {packageItem.adults}
                            </p> */}

                              {/* What's Included */}
                              <div className="mt-3">
                                <strong>What's Included:</strong>
                                <ul className="list-unstyled mb-0">
                                  {packageItem.whatsIncluded.map((include, idx) => (
                                    <li key={idx}>• {include}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </Tab>
                        ))}
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-12 mt-4">
              <h4 style={{ color: '#000' }}>Additional Information</h4>
              <ul className="additional-info list-unstyled">
                <li>• Remember to bring your cake (knife, plates, utensils)</li>
                <li>• 2 free adults per child, each additional adult $5</li>
                <li>
                  • Additional hours must be confirmed when booking. We might not be able
                  accommodate on the day of the party (120/hr)
                </li>
                <li>
                  • Outside food is allowed, $40 charge, and must be approved by staff prior to the
                  event
                </li>
                <li>• Outside beverages not allowed</li>
                <li>
                  • $250 non refundable deposit required to book your event. Balance due at the end
                  of your party.
                </li>
                <li>• Please note that an 8% gratuity will be added to the final bill</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
