import React from "react";
import { Link, useLocation } from "react-router-dom";

const StickyButton = () => {
  const location = useLocation();

  // Check if the current path is '/waiver'
  const isWaiverPage = location.pathname === "/waiver";

  return (
    // Conditionally render the button only if not on the waiver page
    !isWaiverPage && (
      <div className="sticky-button shake-button">
        <Link to="waiver" className="custom-link">
          Waiver
        </Link>
      </div>
    )
  );
};

export default StickyButton;
