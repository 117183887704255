import { Cloudinary } from '@cloudinary/url-gen';

import { scale } from '@cloudinary/url-gen/actions/resize';

const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
  },
});

export { cld, scale };
