import React, { useEffect } from 'react';
import { generatePdf } from '../utils/pdfUtils';

const PdfGenerator = ({ data }) => {
  useEffect(() => {
    if (data) {
      generateAndDownloadPdf(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const generateAndDownloadPdf = async (formData) => {
    try {
      const pdfBase64 = await generatePdf(formData);

      // Create a link to download the PDF
      const link = document.createElement('a');
      link.href = pdfBase64;
      link.download = `Funlandia-Waiver-${formData.firstName}-${formData.lastName}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error generating PDF for download:', error);
    }
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h3>Your waiver has been submitted successfully!</h3>
      <p style={{ color: '#000' }}>
        A copy of your waiver has been emailed to you and sent to our records. Please keep it for
        your records.
      </p>
      <button onClick={() => window.location.reload()} className="btn btn-custom btn-primary">
        Fill Another Waiver
      </button>
    </div>
  );
};

export default PdfGenerator;
