import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default function ScrollToTop() {
    let [show, setshow] = useState();

    useEffect(()=>{
        const scroll =()=>{
            setshow(window.scrollY > 100)
        }
        window.addEventListener('scroll', scroll);
        return ()=>window.removeEventListener('scroll', scroll)
    })
    const handleClick =()=>{
        window.scrollTo(0,0)
    }
  return (
    <>
        <Link to="#" onClick={()=>handleClick()} className={`back-to-top rounded text-center  ${show ? 'd-block' : ''}`} id="back-to-top"> 
            <i className="mdi mdi-chevron-up d-block"> </i> 
        </Link> 
    </>
  )
}
