import React from 'react';
import { motion } from 'framer-motion';
import '../assets/css/ComingSoon.css';

const text = 'Coming Soon...';

const letterVariants = {
  initial: { y: 50, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      damping: 12,
      stiffness: 200,
    },
  },
};

const containerVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
    },
  },
};

export default function ComingSoon() {
  return (
    <motion.div
      className="coming-soon-container"
      initial="initial"
      animate="animate"
      variants={containerVariants}
    >
      <h1 className="coming-soon-text">
        {text.split('').map((char, index) => (
          <motion.span key={index} className="letter" variants={letterVariants}>
            {char}
          </motion.span>
        ))}
      </h1>
      {/* Sparkle Elements */}
      <div className="sparkles">
        <span className="sparkle sparkle1">✨</span>
        <span className="sparkle sparkle2">🌟</span>
        <span className="sparkle sparkle3">💫</span>
      </div>
    </motion.div>
  );
}
