import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import logoDark from "../assets/images/funlandia_logo_podst_siec1.png";
import logoLight from "../assets/images/funlandia_logo_podst_siec1.png";

import { MdMenu } from "../assets/icons/vander";

export default function Navbar() {
  let [toggle, setToggle] = useState(false);
  let [scrolling, setScrolling] = useState(false);
  const location = useLocation();
  useEffect(() => {
    // Scroll to the top when the screen changes
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleLinkClick = () => {
    if (toggle) {
      setToggle(false);
    }
  };

  return (
    <nav
      id="navbar"
      className={`navbar navbar-expand-lg fixed-top navbar-custom navbar-light sticky ${
        scrolling ? "nav-sticky" : ""
      }`}
    >
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={logoDark} className="l-dark" height="80" alt="" />
          <img src={logoLight} className="l-light" height="80" alt="" />
        </Link>
        <button
          className={`navbar-toggler ${toggle ? "" : ""}`}
          onClick={() => setToggle(!toggle)}
        >
          <span>
            <MdMenu />
          </span>
        </button>

        <div
          className={`collapse navbar-collapse ${toggle ? "show" : ""}`}
          id="navbarCollapse"
        >
          <ul id="navbar-navlist" className="navbar-nav ms-auto">
            <li className="nav-item" style={{ color: "#000" }}>
              <Link
                className="nav-link"
                activeclassname="active"
                spy={true}
                smooth={true}
                duration={500}
                to="/"
                onClick={handleLinkClick}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="about"
                activeclassname="active"
                spy={true}
                smooth={true}
                duration={500}
                onClick={handleLinkClick}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                activeclassname="active"
                spy={true}
                smooth={true}
                duration={500}
                to="rules"
                onClick={handleLinkClick}
              >
                Rules
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                activeclassname="active"
                spy={true}
                smooth={true}
                duration={500}
                to="menu"
                onClick={handleLinkClick}
              >
                Menu
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                activeclassname="active"
                spy={true}
                smooth={true}
                duration={500}
                to="hours"
                onClick={handleLinkClick}
              >
                Hours & Fees
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link"
                activeclassname="active"
                spy={true}
                smooth={true}
                duration={500}
                to="reservation"
                onClick={handleLinkClick}
              >
                Reservation
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                activeclassname="active"
                spy={true}
                smooth={true}
                duration={500}
                to="gallery"
                onClick={handleLinkClick}
              >
                Gallery
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                activeclassname="active"
                spy={true}
                smooth={true}
                duration={500}
                to="contact-us"
                onClick={handleLinkClick}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
