import React from "react";
import { motion } from "framer-motion";
import { rules } from "../data/data";
import paper from "../assets/images/background/watercolor-paper-texture.jpg";
import { isMobile } from "../utils/helper";

export default function Rules() {
  const titleAnimation = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  };
  const slideInVariant = {
    hidden: { opacity: 0, x: -100 }, // Starts off-screen to the left
    visible: { opacity: 1, x: 0 }, // Moves into view
  };
  return (
    <div
      style={{
        backgroundImage: `url(${paper})`,
        paddingTop: isMobile ? "20%" : "5%",
        paddingBottom: "5%",
      }}
    >
      <section className="section pb-0" id="services">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title mb-4 pb-2">
                <motion.h4
                  className="title mb-4"
                  initial="hidden"
                  animate="visible"
                  variants={titleAnimation}
                  transition={{ duration: 0.8, ease: "easeInOut" }}
                >
                  Playground Playbook
                </motion.h4>
                <motion.p
                  className="text-muted mx-auto para-desc mb-0"
                  initial="hidden"
                  animate="visible"
                  variants={titleAnimation}
                  transition={{ duration: 0.8, ease: "easeInOut" }}
                >
                  A few friendly guidelines to keep everyone smiling and safe!
                </motion.p>
                {/* <p className="text-muted mx-auto para-desc mb-0">
                  Playing is more fun when everyone is on the same page. These
                  rules are here to make sure that every visit to our playground
                  is full of joy, safety, and plenty of laughs. So, let's stick
                  to the plan and make every moment count!
                </p> */}
              </div>
            </div>
          </div>

          <div className="row">
            {rules.map((item, index) => {
              return (
                <motion.div
                  className="col-lg-4 col-md-6 col-12 mt-4 pt-2"
                  key={index}
                  initial="hidden" // Initial state
                  whileInView="visible" // Animation triggered when in view
                  viewport={{ once: true, amount: 0.5 }} // Trigger when halfway in view
                  transition={{ duration: 0.8, delay: index * 0.2 }} // Staggered entrance
                  variants={slideInVariant}
                >
                  <div className="service-container shadow rounded p-3 text-center">
                    <div className="number-icon position-relative">
                      <div className="icon-2 d-inline-block border rounded-pill">
                        <span className="fw-bold">{item.id}</span>
                      </div>
                    </div>
                    <div className="content mt-2">
                      <h5 className="title text-dark">{item.title}</h5>
                      <p className="text-muted">{item.desc}</p>
                    </div>
                  </div>
                </motion.div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}
