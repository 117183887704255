import jsPDF from 'jspdf';
/**
 * Generates a PDF using jsPDF and returns it as a Base64 string.
 * @param {Object} data - The form data to include in the PDF.
 * @returns {Promise<string>} - A promise that resolves to the Base64 string of the PDF.
 */
export const generatePdf = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const pdf = new jsPDF('portrait', 'pt', 'a4');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const margin = 40;
      const maxLineWidth = pageWidth - margin * 2;
      const lineHeight = 14;
      let cursorY = margin;

      // Header
      pdf.setFontSize(16);
      pdf.setFont('Helvetica', 'bold');
      pdf.text('FUNLANDIA KID CAFÉ', pageWidth / 2, cursorY, { align: 'center' });
      cursorY += 20;

      pdf.setFontSize(12);
      pdf.setFont('Helvetica', 'normal');
      pdf.text('295 N. NORTHWEST HWY UNIT B', pageWidth / 2, cursorY, { align: 'center' });
      cursorY += 15;
      pdf.text('PALATINE, ILL 60067', pageWidth / 2, cursorY, { align: 'center' });
      cursorY += 30;

      // Title
      pdf.setFontSize(18);
      pdf.setFont('Helvetica', 'bold');
      pdf.text('RELEASE OF LIABILITY', pageWidth / 2, cursorY, { align: 'center' });
      cursorY += 30;

      // Introductory Paragraph
      const introParagraph = `I, as parent or legal guardian of the child (or children) whose name is set forth below (each referred to as a "Participant" and collectively referred to herein as the "Participants") and in consideration of the Participants being permitted to participate in the Activities (as defined below) conducted by Funlandia Kid Café located at 295 N. Northwest Hwy Unit B Palatine, IL 60067 (“Funlandia”) agree as follows:`;

      pdf.setFontSize(12);
      pdf.setFont('Helvetica', 'normal');

      const splitIntro = pdf.splitTextToSize(introParagraph, maxLineWidth);
      splitIntro.forEach((line) => {
        if (cursorY + lineHeight > pageHeight - margin - 150) {
          pdf.addPage();
          cursorY = margin;
        }
        pdf.text(line, margin, cursorY, { align: 'left' });
        cursorY += lineHeight;
      });

      cursorY += 10; // Space after paragraph

      // Waiver Points
      const waiverPoints = [
        {
          title: 'ACTIVITIES:',
          text: 'Activities shall be defined as the various activities and services offered to Participants by Funlandia Kid Café, an indoor playground and cafe.',
        },
        {
          title: 'AGREEMENT TO FOLLOW DIRECTIONS:',
          text: 'Participants agree to observe and obey all posted rules and warnings, and further agree to follow any verbal instructions and directions given by Funlandia Kid Café, or the employees of Funlandia Kid Café. In lieu of absent verbal and written rules, warnings and objections, Participants shall act with due regard to other Participants, employees, and property.',
        },
        {
          title: 'ASSUMPTION OF THE RISKS AND RELEASE:',
          text: "Participants recognize that there are certain inherent risks associated with the above-described activity and assume full responsibility for personal injury to oneself and family members and further release and discharge Funlandia Kid Café for injury, loss, or damage arising out of Participants' use of or presence upon the facilities of Funlandia Kid Café whether caused by the fault of Participants, Funlandia Kid Café, or other third parties. Participants expressly agree and promise to accept and assume all risks existing in this activity. Participation in this activity is purely voluntary, and Participants elect to participate despite the risks.",
        },
        {
          title: 'INDEMNIFICATION:',
          text: "Participants agree to indemnify, defend, and hold harmless Funlandia Kid Café against all claims, causes of action, damages, judgments, costs, or expenses, including attorney fees and other litigation costs, which may in any way arise from Participants' use of, or presence upon, the facilities of Funlandia Kid Café.",
        },
        {
          title: 'FEES:',
          text: 'Participants agree to pay for all damages to the facilities of Funlandia Kid Café caused by any neglectful, reckless, or willful actions by Participants.',
        },
        {
          title: 'PHOTO RELEASE:',
          text: 'Participants hereby grant Funlandia Kid Café permission to use images or likeness in a photograph, video, or other digital media in all of its publications, including web-based publications, without payment, consent, or other consideration.',
        },
      ];

      waiverPoints.forEach((point, index) => {
        const pointText = `${index + 1}. ${point.title} ${point.text}`;
        const splitText = pdf.splitTextToSize(pointText, maxLineWidth - 20); // 20pt indentation

        splitText.forEach((line) => {
          if (cursorY + lineHeight > pageHeight - margin - 150) {
            pdf.addPage();
            cursorY = margin;
          }
          pdf.text(line, margin + 20, cursorY, { align: 'left' }); // Indent by 20pt
          cursorY += lineHeight;
        });

        cursorY += 15; // Increased space after each point
      });

      // Conclusion Text
      const conclusionText = `By signing this document, Participants acknowledge that if Participants are injured or property is damaged during participation in Activities, Participants are expressing to a court of law that they have waived any right to maintain or bring upon any lawsuit against Funlandia Kid Café based on any claim from which Participants have released them herein. Participant agrees that this document is valid for all subsequent visits and participation at Funlandia Kid Café. Participants have had sufficient opportunity to read this entire document, have read and understood this document, and agree to be bound by its terms.`;

      const splitConclusion = pdf.splitTextToSize(conclusionText, maxLineWidth);
      splitConclusion.forEach((line) => {
        if (cursorY + lineHeight > pageHeight - margin - 60) {
          pdf.addPage();
          cursorY = margin;
        }
        pdf.text(line, margin, cursorY, { align: 'left' });
        cursorY += lineHeight;
      });

      cursorY += 20; // Space after conclusion

      // Add a new page for Parent/Participant Information
      pdf.addPage();
      //   cursorY = margin;
      const baseY = margin;

      // Parent Information (Left Column)
      const parentInfoX = margin;
      let cursorYParent = baseY;

      pdf.setFontSize(12);
      pdf.setFont('Helvetica', 'bold');
      pdf.text('Parent/Legal Guardian Information', parentInfoX, cursorYParent, { align: 'left' });
      cursorYParent += 15;
      pdf.setFontSize(10);
      pdf.setFont('Helvetica', 'normal');

      const parentInfo = [
        `First Name: ${data.firstName}`,
        `Last Name: ${data.lastName}`,
        `Email Address: ${data.email}`,
        `Phone: ${data.phone}`,
      ];

      parentInfo.forEach((info) => {
        if (cursorYParent + lineHeight > pageHeight - margin - 150) {
          pdf.addPage();
          cursorYParent = margin;
        }
        pdf.text(info, parentInfoX, cursorYParent, { align: 'left' });
        cursorYParent += lineHeight;
      });

      cursorY += 20; // Space after parent info

      // Participants' Information (Right Column)
      const participantInfoX = pageWidth / 2 + 20;
      let cursorYParticipant = baseY;

      pdf.setFontSize(12);
      pdf.setFont('Helvetica', 'bold');
      pdf.text("Participants' Information", participantInfoX, cursorYParticipant, {
        align: 'left',
      });
      cursorYParticipant += 15;
      pdf.setFontSize(10);
      pdf.setFont('Helvetica', 'normal');

      data.children.forEach((child, index) => {
        const childInfo = [
          `Child ${index + 1} Name: ${child.name}`,
          `Child ${index + 1} DOB: ${child.dob}`,
        ];

        childInfo.forEach((info) => {
          if (cursorYParticipant + lineHeight > pageHeight - margin - 100) {
            pdf.addPage();
            cursorYParticipant = margin;
          }
          pdf.text(info, participantInfoX, cursorYParticipant, { align: 'left' });
          cursorYParticipant += lineHeight;
        });

        cursorYParticipant += 10; // Space after each child
      });

      const maxCursorY = Math.max(cursorYParent, cursorYParticipant);
      let finalCursorY = maxCursorY + 20; // Space before signature

      // Signature
      pdf.setFontSize(12);
      pdf.setFont('Helvetica', 'bold');
      pdf.text('Signature:', margin, finalCursorY, { align: 'left' });
      finalCursorY += 15;

      // Add Compressed Signature Image
      const signatureWidth = 200;
      const signatureHeight = 50;
      pdf.addImage(data.signature, 'PNG', margin, finalCursorY, signatureWidth, signatureHeight);
      finalCursorY += signatureHeight + 40;

      // Date Signed
      const today = new Date();
      const formattedDate = today.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      pdf.setFont('Helvetica', 'normal');
      pdf.text(`Date Signed: ${formattedDate}`, margin, finalCursorY, { align: 'left' });
      finalCursorY += 30;

      // Page Numbers
      const totalPages = pdf.internal.getNumberOfPages();

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setFont('Helvetica', 'normal');
        const pageNumberText = `Page ${i} of ${totalPages}`;
        pdf.text(pageNumberText, pageWidth - margin, pageHeight - 20, { align: 'right' });
      }

      // Resolve with Base64 string
      const pdfBase64 = pdf.output('datauristring'); // Includes the prefix
      resolve(pdfBase64);
    } catch (error) {
      reject(error);
    }
  });
};
