import "./App.css";
import { Route, Routes } from "react-router-dom";
import IndexFunlandia from "./pages";
import About from "./pages/About";
import Menu from "./pages/Menu";
import Navbar from "./components/navbar";
import Footer from "./components/Footer";
import Gallery from "./pages/Gallery";
import ContactUs from "./pages/ContactUs";
import Reservation from "./pages/Reservation";
import Rules from "./pages/Rules";
import Hours from "./pages/Hours";
import Waiver from "./pages/Waiver";
import StickyButton from "./components/StickyButton";

function App() {
  return (
    <div
      className="page-wraper"
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Navbar />
      <StickyButton />
      <Routes path="/">
        <Route index element={<IndexFunlandia />} />
        <Route path="/about" element={<About />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/hours" element={<Hours />} />
        <Route path="/waiver" element={<Waiver />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
