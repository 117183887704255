import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { portfolioData } from '../data/data'; // Updated to include publicId
import { isMobile } from '../utils/helper';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { motion } from 'framer-motion';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import ComingSoon from '../components/ComingSoon';
import { AdvancedImage } from '@cloudinary/react';
import { cld, scale } from '../utils/cloudinary';

export default function Gallery() {
  const [selectCategory, setSelectCategory] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [lightboxImages, setLightboxImages] = useState([]);

  useEffect(() => {
    // Generate Lightbox image URLs from publicIds
    const urls = portfolioData.map((item) => {
      const image = cld.image(item.publicId);
      image.resize(scale().width(1200));
      return image.toURL();
    });
    setLightboxImages(urls);
  }, []);

  const handleClick = (index) => {
    setActiveIndex(index);
    setIsOpen(true);
  };

  const filterData = selectCategory
    ? portfolioData.filter((item) => item.category === selectCategory)
    : portfolioData;

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const overlayVariants = {
    hover: {
      opacity: 1,
      scale: 1.05,
      transition: { duration: 0.3, ease: 'easeOut' },
    },
    initial: { opacity: 0 },
  };

  const categoryVariants = {
    initial: { scale: 1 },
    active: { scale: 1.1, color: '#ff6b6b' },
  };

  return (
    <>
      <section
        className="section"
        id="work"
        style={{
          paddingTop: isMobile ? '20%' : '10%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Category Filters */}
        <motion.div
          className="container"
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          <div className="row">
            <div className="col-12 filters-group-wrap text-center">
              <div className="filters-group">
                <ul className="col container-filter portfolioFilter list-unstyled mb-0 text-center filter-options d-flex justify-content-center flex-wrap">
                  {['All', 'Kids', 'Coffee', 'Toys', 'Food'].map((category, idx) => {
                    const categoryKey = category.toLowerCase();
                    return (
                      <li
                        key={idx}
                        className={`list-inline-item mb-2 categories rounded ps-3 pe-3 me-2 ${
                          (category === 'All' && selectCategory === null) ||
                          (category !== 'All' && selectCategory === categoryKey)
                            ? 'active'
                            : ''
                        }`}
                        onClick={() => setSelectCategory(category === 'All' ? null : categoryKey)}
                        data-group={categoryKey}
                      >
                        <motion.span
                          variants={categoryVariants}
                          animate={
                            (category === 'All' && selectCategory === null) ||
                            (category !== 'All' && selectCategory === categoryKey)
                              ? 'active'
                              : 'initial'
                          }
                          whileHover={{ scale: 1.2, color: '#ff6b6b' }}
                          style={{ cursor: 'pointer' }}
                        >
                          {category}
                        </motion.span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Gallery Grid or Coming Soon */}
        <motion.div
          className="container"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <div id="grid" className="row pt-2 mt-4">
            {filterData.length > 0 ? (
              <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                <Masonry>
                  {filterData.map((item, index) => {
                    // Create a CloudinaryImage instance
                    const cldImage = cld.image(item.publicId);
                    cldImage.resize(scale().width(600)); // Corrected transformation

                    return (
                      <motion.div
                        key={item.id}
                        className="picture-item my-2 mx-2 "
                        variants={imageVariants}
                      >
                        <div className="portfolio-box position-relative">
                          <Link
                            className="lightbox"
                            to="#"
                            onClick={() => handleClick(filterData.indexOf(item))}
                            title={item.title}
                          >
                            {/* Replace img with AdvancedImage */}
                            <AdvancedImage
                              cldImg={cldImage}
                              className="img-fluid"
                              alt={item.title || 'Gallery Image'}
                              loading="lazy"
                            />
                            <motion.div
                              className="overlay-work d-flex flex-column justify-content-center align-items-center text-center text-white"
                              variants={overlayVariants}
                            ></motion.div>
                          </Link>
                        </div>
                      </motion.div>
                    );
                  })}
                </Masonry>
              </ResponsiveMasonry>
            ) : (
              <ComingSoon /> // Render the ComingSoon component when no data
            )}
          </div>
        </motion.div>
      </section>

      {/* Lightbox for larger view of images */}
      {isOpen && (
        <Lightbox
          mainSrc={lightboxImages[activeIndex]}
          nextSrc={lightboxImages[(activeIndex + 1) % lightboxImages.length]}
          prevSrc={
            lightboxImages[(activeIndex + lightboxImages.length - 1) % lightboxImages.length]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setActiveIndex((activeIndex + lightboxImages.length - 1) % lightboxImages.length)
          }
          onMoveNextRequest={() => setActiveIndex((activeIndex + 1) % lightboxImages.length)}
          animationDuration={500}
          imageTitle={portfolioData[activeIndex]?.title}
          imageCaption={portfolioData[activeIndex]?.description}
        />
      )}
    </>
  );
}
